<template>
  <div>
    <div v-html="form"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: ""
    };
  },
  created() {
    this.form = this.$route.query.htmls;
    this.$nextTick(() => {
      document.forms[0].submit();
    });
  }
};
</script>

<style></style>
